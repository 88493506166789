<template>
    <div class="col-12 tentative-screen" v-loading="loading">
        <div class="row">
            <div class="col-3 sub-details">
                <i class="fa-sharp fa-thin fa-filter-circle-dollar mr-2"></i>
                Stage
            </div>
            <div class="col-9 text-right">
                <el-select v-model="hubspot_deal_stage_id" @change="stageChange" placeholder="Pipeline Stage" class="mini-select" size="mini">
                    <el-option
                        v-for="item in selectedDealPipeline?.stages"
                        v-if="selectedDealPipeline"
                        :key="'pipeline_stage_' + item.id"
                        :label="item.name"
                        :value="item.id">
                    </el-option>
                </el-select>
                <button
                    id="submit_karen_status_change_request"
                    class="ml-4 btn btn-sm btn-primary"
                    @click="showMarkTentativeModal = true"
                >
                    {{tentativeSale.id ? "Update Tentative" : "Mark Tentative"}}
                </button>
            </div>
        </div>

        <el-dialog
            title="Mark this venue as tentative?"
            :visible.sync="showMarkTentativeModal"
            width="700px"
            :append-to-body="true"
            :close-on-click-modal="false"
            custom-class="mark-tentative-dialog"
        >
            <mark-as-tentative
                :lead-event="leadEvent"
                :tentative-sale="tentativeSale"
                @submit="handleTentativeSubmit"
                @cancel="showMarkTentativeModal = false"
            />
        </el-dialog>
    </div>
</template>

<script>
import { mapState } from "vuex";
import { Select, Option, Button, Dialog } from 'element-ui';
import settingService from '@/services/settings.service';
import MarkAsTentative from '@/views/Widgets/TentativeSales/MarkAsTentative.vue';

export default {
    components: {
        [Select.name]: Select,
        [Option.name]: Option,
        [Button.name]: Button,
        [Dialog.name]: Dialog,
        MarkAsTentative
    },
    props: {
        leadEvent: {
            type: Object,
            required: true,
        },
    },
    data: function() {
        return {
            hubspot_deal_stage_id: this.leadEvent.hubspot_deal_stage_id,
            pipelines: null,
            showMarkTentativeModal: false,
            loading: false
        };
    },
    async created() {
        this.getDealPipelines();
        await this.$store.dispatch(
            "tentativeSales/getTentativeSales",
            this.leadEvent.id
        );
    },
    computed: {
        ...mapState("country/", {
            country: (state) => state.country || 'AU',
        }),
        selectedDealPipeline: function () {
            if (!this.pipelines) {
                return [];
            }
            
            let pipeline = this.pipelines.find((item) => item.id == this.leadEvent.hubspot_deal_pipeline_id);
            return pipeline;
        },
        tentativeSale: function () {
            return this.$store.getters["tentativeSales/getTentativeSalesByLeadEventId"](
                this.leadEvent.id
            );
        }
    },
    methods: {
        getDealPipelines() {
            let pipelines = settingService.getDealPipelines(this.country);
            pipelines.then(data => {
                this.pipelines = data.data;
                console.log('Get Deal Pipelines');
                console.log(this.pipelines);
            });
        },

        handleTentativeSubmit() {
            this.showMarkTentativeModal = false;
            this.reloadLeadEventData();
        },

        async reloadLeadEventData() {
            this.loading = true;
            await this.$store.dispatch("leads/getLeadEvents", this.leadEvent.lead_id);
            await this.$store.dispatch("leadEvent/getSingle", this.leadEvent.id);
            this.loading = false;
        },

        async stageChange() {
            this.loading = true;
            await this.$store.dispatch(
                'leadEvent/updateDealStage', 
                {
                    leadEventId: this.leadEvent.id,
                    dealStageId: this.hubspot_deal_stage_id
                }
            );
            this.reloadLeadEventData();
        }
    },
    watch: {
        'leadEvent.hubspot_deal_stage_id': {
            handler: function() {
                this.hubspot_deal_stage_id = this.leadEvent.hubspot_deal_stage_id;
            },
            deep: true,
            immediate: true,
        }
    }
};
</script>

<style>
.tentative-screen .sub-details {
    color: #6f6f6f;
    font-size: 13px;
    display: flex;
    align-items: center;
}
.tentative-screen .mini-select input {
    height: 30px !important;
    font-size: 13px !important;
}
.mark-tentative-dialog .el-dialog__body {
    padding: 0;
}
</style>
